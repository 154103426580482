import React, { useState } from "react"
import { Footer, Navbar } from "."
import "animate.css/animate.min.css"
import "../../../static/styles.css"
import FloatingButton from "./FloatingButton"
import { Helmet } from "react-helmet"

const Layout = ({ children, ...props }) => {
  return (
    <div
      style={{
        minHeight: "100vh",
        position: "relative",
      }}
      className="font-sans"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Keyla Technologies</title>
        <meta
          name="title"
          content="Keyla Technologies - Keyla is a technology solution service provider in the global market. We offer technology services across the internet technology ecosystem. Including website development, mobile app development, AI solutions, intelligent chatbots, payment solutions, UI/UX solutions, etc, to businesses, organisations, institutions, NGOs, etc. Keyla is a company focused on delivering quality services to its customers at all time, in order to provide customer satisfaction and increase overall business efficiency"
        />
        <meta
          name="description"
          content="Keyla is a technology solution service provider in the global market. We offer technology services across the internet technology ecosystem. Including website development, mobile app development, AI solutions, intelligent chatbots, payment solutions, UI/UX solutions, etc, to businesses, organisations, institutions, NGOs, etc. Keyla is a company focused on delivering quality services to its customers at all time, in order to provide customer satisfaction and increase overall business efficiency"
        />

        {/* <!-- Google meta tags --> */}
        <meta
          itemprop="name"
          content="Keyla Technologies - Keyla is a technology solution service provider in the global market. We offer technology services across the internet technology ecosystem. Including website development, mobile app development, AI solutions, intelligent chatbots, payment solutions, UI/UX solutions, etc, to businesses, organisations, institutions, NGOs, etc. Keyla is a company focused on delivering quality services to its customers at all time, in order to provide customer satisfaction and increase overall business efficiency"
        />
        <meta
          itemprop="description"
          content="Keyla is a technology solution service provider in the global market. We offer technology services across the internet technology ecosystem. Including website development, mobile app development, AI solutions, intelligent chatbots, payment solutions, UI/UX solutions, etc, to businesses, organisations, institutions, NGOs, etc. Keyla is a company focused on delivering quality services to its customers at all time, in order to provide customer satisfaction and increase overall business efficiency"
        />
        <meta itemprop="image" content="https://keyla.ng/logo.svg" />

        {/* <!-- Facebook meta tags --> */}
        <meta property="og:url" content="https://keyla.ng/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Keyla Technologies - Keyla is a technology solution service provider in the global market. We offer technology services across the internet technology ecosystem. Including website development, mobile app development, AI solutions, intelligent chatbots, payment solutions, UI/UX solutions, etc, to businesses, organisations, institutions, NGOs, etc. Keyla is a company focused on delivering quality services to its customers at all time, in order to provide customer satisfaction and increase overall business efficiency"
        />
        <meta
          property="og:description"
          content="Keyla is a technology solution service provider in the global market. We offer technology services across the internet technology ecosystem. Including website development, mobile app development, AI solutions, intelligent chatbots, payment solutions, UI/UX solutions, etc, to businesses, organisations, institutions, NGOs, etc. Keyla is a company focused on delivering quality services to its customers at all time, in order to provide customer satisfaction and increase overall business efficiency"
        />
        <meta property="og:image" content="https://keyla.ng/logo.svg" />

        {/* <!-- Twitter meta tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Keyla Technologies - Keyla is a technology solution service provider in the global market. We offer technology services across the internet technology ecosystem. Including website development, mobile app development, AI solutions, intelligent chatbots, payment solutions, UI/UX solutions, etc, to businesses, organisations, institutions, NGOs, etc. Keyla is a company focused on delivering quality services to its customers at all time, in order to provide customer satisfaction and increase overall business efficiency"
        />
        <meta
          name="twitter:description"
          content="Keyla is a technology solution service provider in the global market. We offer technology services across the internet technology ecosystem. Including website development, mobile app development, AI solutions, intelligent chatbots, payment solutions, UI/UX solutions, etc, to businesses, organisations, institutions, NGOs, etc. Keyla is a company focused on delivering quality services to its customers at all time, in order to provide customer satisfaction and increase overall business efficiency"
        />
        <meta name="twitter:image" content="https://keyla.ng/logo.svg" />
      </Helmet>
      <Navbar lightNav={props.lightNav} contactNav={props.contactNav} />
      <main
        style={{
          minHeight: "100vh",
        }}
      >
        {children}
      </main>
      <FloatingButton />
      <Footer />
    </div>
  )
}

export default Layout
