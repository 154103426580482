import React, { useState } from "react"
// import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import "./styles/global.css"
import BrandLogo from "../../../static/assets/brand/logo.svg"
import { AiOutlineClose } from "react-icons/ai"
import { HiOutlineMenu } from "react-icons/hi"

const Navbar = props => {
  // const [menuState, setMenuState] = React.useState(false)
  // const [active, setActive] = useState("")
  const [toggle, setToggle] = useState(false)

  const pathname = typeof window !== 'undefined' ? window.location.pathname : "";

  return (
    <nav
      className={`${
        pathname === "/" || pathname === "/services" || pathname === "/about"
          ? "bg-black"
          : "bg-white"
      } w-full px-2 md:px-16 relative top-0 z-50`}
    >
      <div className="flex flex-row items-center justify-between">
        <div>
          <Link to="/" className="w-fit">
            <img src={BrandLogo} className="w-32" alt="Logo" />
          </Link>
        </div>

        <div className="md:flex hidden flex-row items-center gap-10">
          {pathname === "/" ||
          pathname === "/services" ||
          pathname === "/about" ? (
            <>
              <Link
                to="/about"
                className={`${
                  pathname === "/about"
                    ? "text-primary border border-x-0 border-t-0 border-b-primary "
                    : "text-white"
                } md:text-lg hover:text-primary`}
              >
                About
              </Link>
              <Link
                to="/services"
                className={`${
                  pathname === "/services"
                    ? "text-primary border border-x-0 border-t-0 border-b-primary "
                    : "text-white"
                } md:text-lg hover:text-primary`}
              >
                Services
              </Link>
              <Link
                to="/accelerator"
                className={`${
                  pathname === "/accelerator"
                    ? "text-primary border border-x-0 border-t-0 border-b-primary "
                    : "text-white"
                } md:text-lg hover:text-primary`}
              >
                Accelerator
              </Link>
              <Link
                to="/contact"
                className={`${
                  pathname === "/contact"
                    ? "text-primary border border-x-0 border-t-0 border-b-primary "
                    : "text-white"
                } md:text-lg hover:text-primary`}
              >
                Contact
              </Link>
            </>
          ) : (
            <>
              <Link
                to="/about"
                className={`${
                  pathname === "/about"
                    ? "text-primary border border-x-0 border-t-0 border-b-primary "
                    : "text-black"
                } md:text-lg hover:text-primary`}
              >
                About
              </Link>
              <Link
                to="/services"
                className={`${
                  pathname === "/services"
                    ? "text-primary border border-x-0 border-t-0 border-b-primary "
                    : "text-black"
                } md:text-lg hover:text-primary`}
              >
                Services
              </Link>
              <Link
                to="/accelerator"
                className={`${
                  pathname === "/accelerator"
                    ? "text-primary border border-x-0 border-t-0 border-b-primary "
                    : "text-black"
                } md:text-lg hover:text-primary`}
              >
                Accelerator
              </Link>
              <Link
                to="/contact"
                className={`${
                  pathname === "/contact"
                    ? "text-primary border border-x-0 border-t-0 border-b-primary "
                    : "text-black"
                } md:text-lg hover:text-primary`}
              >
                Contact
              </Link>
            </>
          )}
        </div>

        {/* Icon */}
        <div
          onClick={() => setToggle(prev => !prev)}
          className="cursor-pointer z-50 md:hidden"
        >
          {toggle ? (
            <AiOutlineClose
              size={35}
              color={`${
                pathname === "/" ||
                pathname === "/services" ||
                pathname === "/about"
                  ? "white"
                  : "black"
              }`}
            />
          ) : (
            <HiOutlineMenu
              size={35}
              color={`${
                pathname === "/" ||
                pathname === "/services" ||
                pathname === "/about"
                  ? "white"
                  : "black"
              }`}
            />
          )}
        </div>

        {/* Mobile Menu */}
        <div
          className={`${
            toggle ? "flex" : "hidden"
          } absolute flex-col justify-center items-center gap-4 bg-white top-20 rounded w-[250px] right-3 h-[250px]`}
        >
          <Link
            to="/"
            className={`${
              pathname === "/"
                ? "text-primary border border-x-0 border-t-0 border-b-primary "
                : "text-black"
            } md:text-lg hover:text-primary`}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={`${
              pathname === "/about"
                ? "text-primary border border-x-0 border-t-0 border-b-primary "
                : "text-black"
            } md:text-lg hover:text-primary`}
          >
            About
          </Link>
          <Link
            to="/services"
            className={`${
              pathname === "/services"
                ? "text-primary border border-x-0 border-t-0 border-b-primary "
                : "text-black"
            } md:text-lg hover:text-primary`}
          >
            Services
          </Link>
          <Link
            to="/accelerator"
            className={`${
              pathname === "/accelerator"
                ? "text-primary border border-x-0 border-t-0 border-b-primary "
                : "text-black"
            } md:text-lg hover:text-primary`}
          >
            Accelerator
          </Link>
          <Link
            to="/contact"
            className={`${
              pathname === "/contact"
                ? "text-primary border border-x-0 border-t-0 border-b-primary "
                : "text-black"
            } md:text-lg hover:text-primary`}
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
//  <div
//       className={`${
//         props.lightNav ? "navbar-light" : "navbar-container"
//       } pr-4 `}
//     >
//       <Link to="/">
//         <img src={BrandLogo} className="logo" alt="Logo" />
//       </Link>
//       <div className="nav-link-container">
//         <>
//           <Link
//             className="nav-link"
//             activeClassName="nav-link"
//             to="/about"
//           >
//             <h2 className="md:text-lg ">About</h2>
//           </Link>
//           <Link
//             className="nav-link"
//             activeClassName="nav-link-selected"
//             to="/services"
//           >
//             <h2 className="md:text-lg ">Services</h2>
//           </Link>
//           <Link
//             className="nav-link"
//             activeClassName="nav-link-selected"
//             to="/accelerator"
//           >
//             <h2 className="md:text-lg ">Accelerator</h2>
//           </Link>
//           <Link
//             className="nav-link"
//             activeClassName="nav-link-selected"
//             to="/contact"
//           >
//             <h2 className="md:text-lg ">Contact</h2>
//           </Link>
//           {/* <Link className="nav-button" to="/free-website">
//             Apply for free website
//           </Link> */}
//           <div  className={`${} dropdown-container`}>
//             <img
//               src={props.lightNav ? MenuIconBlack : MenuIcon}
//               alt="Menu"
//               className="menu-icon"
//               onClick={()=> setMenuState((prev)=> !prev)}
//             />
//             <NavDropdown open={menuState} />
//           </div>
//         </>
//       </div>
//     </div>
