import React from "react"
import MessageIcon from "../../../static/assets/brand/message.svg"
import "./styles/global.css"
import { Link } from "gatsby"

function FloatingButton() {
  return (
    <Link to="/services#service-page-inquiry">
      <div className="floating-action-button">
        <img src={MessageIcon} alt="Chat" />
      </div>
    </Link>
  )
}

export default FloatingButton
