import { Link } from "gatsby"
import React from "react"
import FacebookIcon from "../../../static/assets/footer/facebook.svg"
import InstagramIcon from "../../../static/assets/footer/instagram.svg"
// import TwitterIcon from "../../../static/assets/footer/twitter.svg"
import LinkedinIcon from "../../../static/assets/footer/linkedin.svg"
import "./styles/global.css"

const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <div className="footer-container">
      <div className="footer-link-container">
        <Link className="footer-link" to="/about">
          About us
        </Link>
        <Link className="footer-link" to="/services">
          Services
        </Link>
        <Link className="footer-link" to="/accelerator">
          Accelerator
        </Link>
        <Link className="footer-link" to="/contact">
          Contact us
        </Link>
      </div>
      <div className="footer-social-container">
        <span className="social-title">FOLLOW US ON:</span>
        <div className="social-links-containter">
          <a
            href="https://web.facebook.com/Keyla-Technologies-103335601796481"
            target="_blank"
            rel="noreferrer"
          >
            <img src={FacebookIcon} alt="Facebook" className="social-icon" />
          </a>
          <a
            href="https://www.instagram.com/keyla.ng/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={InstagramIcon} alt="Instagram" className="social-icon" />
          </a>
          {/* <a href="">
            <img src={TwitterIcon} alt="Twitter" className="social-icon" />
          </a> */}
          <a
            href="https://www.linkedin.com/company/keylatech/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LinkedinIcon} alt="Linkedin" className="social-icon" />
          </a>
        </div>
      </div>
      <div className="footer-footer">
        <span className="footer-text">
          Copyright &copy; Keyla {currentYear}
        </span>
        {/* <Link to="/privacy-policy" className="footer-text">
          Privacy Policy
        </Link> */}
      </div>
    </div>
  )
}

export default Footer
